<template>
	<div
		:class="['panel', 'subscribe_plans',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div v-if="isHeaderVisible" :class="'panel_header' + (isBrand ? ' sk' : '')">
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null && 'type_titles' in fetchedData">
							Пакетные предложения {{ fetchedData.type_titles.title_genitive }}
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>

			<div class="plan-table-wrapper row">
				<div class="plan-table col-lg-12 d-none d-lg-flex">
					<div class="plan-row-header">
						<div class="plan-row-header__title" v-html="block.items.header.title">
							{{ block.items.header.title }}
						</div>
						<div
							v-for="columns in block.items.header.columns"
							:key="columns"
							class="plan-row-header__columns">
							<div class="column font-weight-bolder" v-html="columns">
								{{ columns }}
							</div>
						</div>
					</div>
					<div v-for="(body, i) in block.items.body" :key="i" class="plan-row-body">
						<div class="plan-row-body__title" v-html="body.title">
							{{ body.title }}
						</div>
						<div v-for="(columns, y) in body.columns" :key="y" class="plan-row-body__columns">
							<div class="column">
								<div class="column" v-if="columns === '1'">
									<span class="icon icon-check-big bg-success"></span>
								</div>
								<div class="column" v-else-if="columns === '0'">
									<span class="icon icon-minus bg-secondary"></span>
								</div>
								<div v-else v-html="columns"></div>
							</div>
						</div>
					</div>
					<div class="plan-row-footer font-weight-bolder">
						<div class="plan-row-footer__title" v-html="block.items.footer.title">
							{{ block.items.footer.title }}
						</div>
						<div
							v-for="(columns ,c) in block.items.footer.columns"
							:key="c"
							class="plan-row-footer__columns"
						>
							<div class="column" v-html="columns">
								{{ columns }}
							</div>
						</div>
					</div>
				</div>

				<div class="plan-tabs col-12 d-lg-none">
					<div class="tabs">
						<div class="tablist">
							<div
								v-for="(content, index) in block.mobileItems"
								:key="index"
								class="tab-panel"
							>
								<div class="tab-panel__items" @click="openItem(content, index)">
									<div class="item font-weight-bolder" v-html="content.head">
										{{ content.head }}
									</div>
									<div class="icon icon-plus d-flex align-self-center"></div>
								</div>
								<div  v-if="activeIndex === index" class="tab-panel__body">
									<ul>
										<li v-for="item in content.rows" :key="item" v-html="item">
											{{ item }}
										</li>
										<li v-html="content.footer">
											{{ content.footer }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isFooterVisible" class="panel_footer">
				<div v-if="block.footer" class="description mb-4" v-html="block.footer"/>
				<dynamic-button
					v-if="block.button"
					class="mt-4"
					:button="block.button"
					:block-id="block.id"
					:scenario="block.scenario"
				/>
			</div>
		</div>
	</div>
</template>

<script>

import panel from '~/mixins/panel';

export default {
	name: 'SubscribePlans',
	mixins: [panel],
	data() {
		return {
			active: {},
			activeIndex: null,
		};
	},
	methods: {
		openItem(v, i) {
			if (this.activeIndex === i) {
				this.activeIndex = null;
				return;
			}
			this.activeIndex = i;
			this.active = v;
		},
	},
};
</script>

<style lang="scss">
@import '../../../assets/styles/sk_variables';
// For use bootstrap vars and mixins inside
@import '../../../assets/styles/bootstrap/scss/functions';
@import '../../../assets/styles/bootstrap/scss/variables';
@import '../../../assets/styles/bootstrap/scss/mixins';

.plan-table-wrapper {
	p {
		margin-bottom: 0 !important;
	}

	.plan-table {
		display: flex;
		flex-direction: column;
		line-height: 1.3;

		&.col-xl {
			@media only screen and (max-width: 1260px) {
				display: none;
			}
		}

		.main-header {
			padding: 20px;
			font-size: 32px;
			font-weight: 400;
			text-align: center;
		}
	}

	.plan-row-header {
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		padding: .5rem;
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		background-color: $primary;
		color: $white;
		font-size: 0.8rem;

		&__title {
			width: 50%;
			text-align: left;
			padding: .5rem .5rem;
		}

		&__columns {
			display: flex;
			justify-content: space-between;
			width: 50%;
			padding: .5rem .5rem;

			.column {
				display: flex;
				flex: 1;
				justify-content: center;
				text-align: center;
			}
		}
	}

	.plan-row-body, .plan-row-footer {
		display: flex;
		padding: .75rem 1rem;
		font-size: .8rem;

		&:nth-child(odd) {
			background-color: $table-accent-bg;
		}

		&:nth-child(even) {
			background-color: $white;
		}

		&:hover {
			background-color: $table-hover-bg;
		}

		&__title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 50%;
			padding: 0;
		}

		&__columns {
			display: flex;
			justify-content: center;
			width: 50%;

			.column {
				display: flex;

				flex: 1;
				align-items: center;
				justify-content: center;

				span {
					justify-content: center;
					font-size: .5rem;
					display: flex;
					border-radius: 50%;
					padding: 0.45rem;
					color: $white;
				}

			}
		}
	}

	.plan-row-footer {
		background-color: $primary !important;
		color: $white;
		padding: 1rem;
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
	}

	.plan-tabs {
		display: flex;
		flex-direction: column;
		width: 100%;

		&.col-xl {
			@media only screen and (min-width: 1261px) {
				display: none;
			}
		}

		.main-header {
			padding: 20px;
			font-size: 20px;
			font-weight: 400;
			text-align: center;
		}

		.tabs {
			.tablist {
				&__tabs {
					display: flex;
					justify-content: center;
					padding: 0;
					margin: 0;
					text-align: center;
					list-style: none;

					li {
						padding: 11px;
						cursor: pointer;
					}
				}
			}

			.tab-panel {
				&__items {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 16px;
					margin: 16px 0 0;
					text-align: left;
					cursor: pointer;
					background-color: $primary;
					color: $white;
				}

				&__body {
					margin-bottom: -16px;

					ul {
						list-style: none;
						margin: 0;
						padding: 0;

						li {
							padding: 16px;
							text-align: left;
							border-bottom: 1px solid #f0f0f0;

							&:nth-child(odd) {
								background-color: $table-accent-bg;
							}

							&:nth-child(even) {
								background-color: $white;
							}

							&:last-child {
								background-color: $primary;
								color: $white;
							}

						}
					}
				}
			}
		}
	}
}
</style>
